import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query'; // Import React Query


const queryClient = new QueryClient(); // Initialize React Query client

// Lazy-loaded components
const LazyLogin = lazy(() => import('./pages/login/Login'));
const LazyRegister = lazy(() => import('./pages/register/Register'));
const LazyApplication = lazy(() => import('./pages/application/Application'));
const LazyForgotPassword = lazy(() => import('./pages/password/ForgotPassword'));
const LazyResetPassword = lazy(() => import('./pages/password/ResetPassword'));
const LazyInvite = lazy(() => import('./pages/invite/Invite'));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    isAuth(); // Check authentication on component mount
  }, []);

  async function isAuth() {
    try {
      const response = await axios.get('/auth/verify', { headers: { token: localStorage.token } });
      response.data === true ? setIsAuthenticated(true) : setIsAuthenticated(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Suspense>
          <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to='/application' /> : <LazyLogin setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/register" element={isAuthenticated ? <Navigate to='/' /> : <LazyRegister setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/application/*" element={isAuthenticated ? <LazyApplication setIsAuthenticated={setIsAuthenticated} /> : <Navigate to='/' />} />
            <Route path="/password" element={<LazyForgotPassword />} />
            <Route path="/password/reset/:user_id/:token" element={<LazyResetPassword />} />
            <Route path="/invite/:customer_id/:token/:role" element={isAuthenticated ? <Navigate to='/' /> : <LazyInvite setIsAuthenticated={setIsAuthenticated} />} />
          </Routes>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;

